
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import CoachNavigationVue from "@/components/coach/navigation.vue";
import CoachResourcesNavigationVue from "@/components/coach/resources/navigation.vue";
import AppCoachResourcesSupplementsListIndexVue from "@/views/app/coach/resources/supplements/list/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    CoachNavigationVue,
    CoachResourcesNavigationVue,
    AppCoachResourcesSupplementsListIndexVue,
  },
})
export default class AppCoachResourcesSupplementsIndexVue extends Vue {}
