// Store
import store from "@/store";

export interface AppCoachResourcesSupplementsListFormCreate {
  organization_id: string;
  content: {
    name: string;
    description: string;
  };
}

export class AppCoachResourcesSupplementsListFormCreate implements AppCoachResourcesSupplementsListFormCreate {
  organization_id: string;
  content: {
    name: string;
    description: string;
  };

  constructor() {
    this.organization_id = store.getters.getOrganizationMember.organization_id ?? "";
    this.content = {
      name: "",
      description: "",
    };
  }
}